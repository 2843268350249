
.screen-modal-header{
    padding: 1rem 1.5rem !important;
}

.screen-modal-body{
        min-width: 100%;
        height:auto;
        display: flex;
                //min-height: calc(80vh - 88.5px);
        @media screen and(max-width: 768px){
            //min-height: calc(100vh - 88.5px);
        }
}

