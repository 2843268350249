.cdn-upload-popup {
    display: flex;
    width: 100%;
    flex-direction: column;
    padding: 0px 30px 30px 30px;

    h5 {
        margin: 30px 0px 30px 0px;

        &:first-child {
            margin-top: 0px;
        }
    }

    .cdn-upload-popup-imported-files-downloading {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .cdn-upload-source-select {
        display: flex;
        width: 100%;
        height: 140px;
        flex-wrap: wrap;
        min-height: fit-content;

        .cdn-upload-source-button {
            height: 50px !important;
            min-width: 180px;
            margin-right: 25px;
            background: transparent;
            color: black;
            display: flex;
            align-items: center;
            margin-top: 10px;
            margin-bottom: 10px;

            .icon {
                margin: 10px 10px 10px 0px;
                height: 25px;
                width: 25px;
                object-fit: contain;
            }
        }

        .active {
            background: #f5f5f5;
            border-color: #3C7AD8;
            border-width: 2px;
            outline: #3C7AD8;
        }
    }

    .seperator {
        margin: 20px 0px 0px 0px;
        border-bottom: 2px solid #e0e0e0;
    }
}