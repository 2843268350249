.comments-container{
        height: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;
            flex-direction: column;
            width: 100%;
      
            .comments {
                padding: 10px;
                width: 100%;
                height: 100%;
                overflow-y: auto;
               .comment{
                    margin-bottom: 4px;
                    .message {
                        
                            padding: 5px;
                            width: 100%;
                            display: flex;
                            align-items: flex-start;
                            justify-content: space-between;
                    
                            .comment-avatar {
                                width: 35px;
                                height: 35px;
                                border-radius: 50%;
                                margin-right: 10px;
                            }
                    
                            .comment-content {
                                width: 100%;
                                background: #fff;
                                padding: 10px;
                                    border-radius: 0 15px 15px 15px;
                                    margin-bottom: 10px;
                    
                                .comment-header {
                                    display: flex;
                                    align-items: center;
                                    justify-content: space-between;
                    
                                    .comment-author {
                                        font-weight: lighter;
                                    }
                    
                                    .comment-date {
                                        font-size: 12px;
                                        color: #9e9e9e;
                                    }
                                }
                            }
                    
                        }
               }
             
            }
            
                .write-comment-block {
                    width: 100%;
                    background: #e5e5ee;
                    padding: 10px;

                    .comment-input{
                        width: 100%;
                        min-height: 80px;
                        border: none!important;
                        outline: none!important;
                        background: #e5e5ee;
                        font-size: 14px;
                        resize: none;
                        margin: 0;
                        padding: 0;
                        color: red;
    

                        *{
                            border-color: #e5e5ee!important;
                            outline: none!important;
                            &::placeholder {
                                    color: #9e9e9e;
                                }
                        }
                    }
                }
}
