.add-contract-switch {
    input[type="checkbox"] {
        width: 3.75rem !important;
        height: 1.5rem !important;
    }
}

.react-select-inner-button{
    width: 100%;
    padding: 10px;
}