.cdn-popup-files-table {
    display: flex;
    width: 100% !important;
    min-height: 60vh;
    align-items: flex-start;
    flex-direction: column;
        overflow-x: scroll;

    @media screen and (max-width:1200px) {
        overflow-x: scroll;
    }



    .table-responsive {
        width: 100%;
        overflow-x: auto;
        overflow-y: hidden;
    }

    .upload-file-row {
        width: 100%;
        height: 100px !important;

        td {
            padding: 0px;

            a {
                color: blue !important;
                font-weight: bold;
            }
        }

        .filename {
            overflow-wrap: break-word;
            //width: 240px;
            max-width: 190px;
        }

        .contracts-edit {
            min-width: 240px;
        }

        .files-edit {
            min-width: 240px;
        }

        .tags-edit {
            min-width: 220px;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            position: relative;

            input {
                text-align: center;
            }

            small {
                position: absolute;
                bottom: 5px;
                width: 100%;
                transform: translateX(-50%);
            }
        }

        .file-size {
            min-width: 100px;
        }

    }
}