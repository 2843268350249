.modal-footer {
    min-height: 60px;

    button.companyOnboard-previous {
        position: absolute;
        left: 15px;
    }

    button.companyOnboard-next {
        position: absolute;
        right: 15px;

        &:disabled {
            background-color: gray;
            border-color: gray;
            outline: none;
            cursor: none;
        }
    }
}