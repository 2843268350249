.pdf-wrapper {
    padding: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .pdf-iframe-left {
        width: 75%;
        height: 100%;
        padding: 10px;
        .pdf-viewer {
            width: 100%;
            border: none;
        }
    }

    .pdf-iframe-right {
        width: 25%;
        height: 100vh;
        padding: 10px;
        background: #eeeeee;
    }
}