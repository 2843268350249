.modal-footer {
    min-height: 60px;

    button.companySwitch-finish {
        position: absolute;
        right: 15px;

        &:disabled {
            background-color: gray;
            border-color: gray;
            outline: none;
            cursor: none;
        }
    }
}


.company-switch {
    display: flex;
    width: 100%;

    .company-switch-row {
        width: 100%;

        div.innerContent {
            width: 100%;
            height: 100%;

            @media screen and (min-width:992px) {
                padding: 0px 0px;
            }

            @media screen and (max-width:992px) {
                min-height: 100% !important;
            }

            .choose-company {
                width: 100%;
                border: 1px solid black;
                border-radius: 3px;
                margin-bottom: 20px;

                .radio {
                    height: 75px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }

                .main {
                    height: 75px;
                    display: flex;
                    align-items: center;
                    justify-content: flex-start;
                    font-family: inherit;
                    font-weight: bold;
                    font-size: 17px;
                }

                .arrow {
                    height: 75px;
                    display: flex;
                    align-items: center;
                    justify-content: flex-end;
                    padding-right: 20px;
                }
            }


        }
    }
}