.aprrove-process{
    max-height: 800px;
    overflow-y: auto;
.header-fixed {
        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.7);
    }

    .approve__header {
        background: white;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 10px 20px;
        padding: 20px 0 0 0;
        margin-bottom: 20px;
/*         position: -webkit-sticky;
        position: sticky; */
        top: 0;
.approve__header__top{
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 20px;
  /*       position: -webkit-sticky;
        position: sticky; */
        top: 0;

        .approve-message-textarea{
            width: 100%;
            height: auto;
            min-height: 80px;
            border: 1px solid hsl(0, 0%, 80%);;
            border-radius: 0px;
            padding: 8px;
            font-size: 14px;
            color: #000;
            border-radius: 5px;
            &:focus{
                outline: none;
            }
            &::placeholder{
                color: #a1a8b0 !important;
                    font-size: 14px;
            }
        }
}

.approve__header__body{
    width: 100%;
    background: white;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 10px 20px;
        padding: 20px 0 0 20px;
        margin-bottom: 20px;
      /*   position: -webkit-sticky;
        position: sticky; */
        top: 0;
.approve__header__title {
        font-size: 16px;
        font-weight: 400;
        color: #000;
        width: 40%;

        input {
            width: 100%;
            height: 32px;
            border: 1px solid #e9e9e9;
            border-radius: 0px;
            padding: 0 10px;

            &:focus {
                outline: none;
            }

        }
    }

    .approve__header__actions {
        display: flex;
        align-items: center;

        .approve__header__actions__button {
            color: #2D42FF;
            font-size: 14px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            line-height: 14px;

        }
    }
}

       


    }

    .steps {
        padding: 20px;
        padding-top: 0;

        .step {
            padding: 20px;
            width: 100%;
            background: #e9e9e9;
            margin-bottom: 20px;
            border-radius: 5px;

            .step-number {
                display: flex;
                align-items: center;
                justify-content: space-between;
            }

            .recipients {
                background: #ffffff;
                
                padding: 10px;
                display: flex;
                align-items: flex-start;
                justify-content: flex-start;
                flex-wrap: wrap;
                gap: 5px;
                border-radius: 5px;
                margin-bottom: 10px;

                .recipients-new {
                    width: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;

                    input {
                        text-align: center;
                        border-bottom-right-radius: 0;
                        border-top-right-radius: 0;
                        border-color: #e9e9e9;
                    }

                    .add-recipient-button {
                        border-bottom-left-radius: 0;
                        border-top-left-radius: 0;
                        border: 1px solid #050027;
                        outline: 1px solid #050027;
                    }
                }

                .recipient {
                    width: 100%;
                    overflow: hidden;
                    border-radius: 5px;
                    border: 1px solid #e9e9e9;
                    padding: 10px;
                    

                    .recipient-group {
                        width: 100%;
                        display: flex;
                        align-items: center;
                        justify-content: space-between;

                        .recipient-label {
                            background: #3C7AD8;
                            color: #ffffff;
                            padding: 5px 10px;
                            border-radius: 5px;
                        }
                        .recipient-settings {
                            display: flex;
                            align-items: center;
                            justify-content: flex-end;
                            gap: 10px;
                            .form-check-inline {
                                margin-right: 0;
                                margin-bottom: 0;
                                line-height: 14px;
                            }
                            .btn{
                                padding: 0;
                                width: 20px;
                                height: 20px;
                                color: #303030;
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                font-size: 12px;
                                cursor: pointer;
                                &:hover {
                                    color: #000;
                                }
                            }
                        }
                    }
                }
            }

            .step-settings {
                padding: 10px;
                height: 50px;
                padding-left: 0 !important;
                display: flex;
                align-items: center;
                justify-content: flex-start;
                gap:10px;
            }

            
        }


    }






    .recipient-suggestions {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        flex-wrap: wrap;
        gap: 5px;
        color: blue;


    }

    /* ENDLESS ROTATE */
    .rotate {
        animation: rotate 1.5s linear infinite;
    }

    @keyframes rotate {
        to {
            transform: rotate(360deg);
        }
    }
}



.form-check-label {

    line-height: 23px;
}

.form-check .form-check-input {
    float: left;
    margin-left: -1.3rem;
}

