//Create variable for colors
$approve-red: #F0233B;
$approve-green: #2EB818;
.internal-approve-modal-dialog{
    width: 1600px;
    max-width: 100%;
   

}
.internal-approve-popup {
    padding: 0;
    width: 100%;
    height: calc(100vh - 9rem);
    display: flex;
    align-items: flex-start;
    justify-content: space-between;

    .internal-approve-left {
        width: 60%;
        height: 100%;
        padding: 10px;
        .internal-viewer{
            width: 100%;
            height: 100%;
           
            .google-drive-approve-iframe {
                width: 100%;
                height: 100%;
            }
        }
        
    }

    .internal-approve-right {
        width: 40%;
        height: 100%;
        overflow-y: auto;
        padding: 10px;
        background: #eeeeee;
        .internal-approve-buttons{
            
            width: 100%;
            .external-approve-actions {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    flex-direction: column;
                    gap: 20px;
                    margin-top: 20px;
                    padding: 20px;
            
                    .button-reject {
                        button{
                            background: $approve-red;
                            border: $approve-red;
                        }
                        width: 100%;
                    }
                    .button-approve-dd {
                        button{
                            background: $approve-green;
                            border: $approve-green;
                        }
                        width: 100%;
                    }
            
                    .button-approve {
                        background: $approve-green;
                        border: $approve-green;
                        width: 100%;
                    }
            
                    .button-change-assign {
                        width: 100%;
                    }
                }
        }
        .approve-files{
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: flex-start;
            .approve-file{
                
                width: 100%;
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                justify-content: flex-start;
                margin-top: 20px;
                overflow: hidden;
                border-radius: 7px;
                
                .approve-file-header {
                        padding: 0 10px;
                        border-radius: 5px 5px 0 0;
                        width: 100%;
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        justify-content: space-between;
                        overflow: hidden;
                
                        .approve-file-header-title {
                            font-weight: bold;
                            display: flex;
                            align-items: center;
                            justify-content: flex-start;
                            gap:6px;
                            padding-left: 9px;
                        }
                
                        .approve-file-header-action {
                            display: flex;
                            flex-direction: row;
                            align-items: center;
                            justify-content: flex-end;
                            gap: 5px;
                            color: white;
                            button{
                                svg{
                                    fill: white;
                                    color: white;
                                }
                            }
                        }
                    }

                .approve-file-body{
                    width: 100%;
                    background: #fff;
                    border-radius: 0 0 5px 5px;
                    .approve-file-attachments {
                            width: 100%;
                            display: flex;
                            flex-direction: column;
                            align-items: flex-start;
                            justify-content: flex-start;
                            gap: 5px;
                            background: #fff;
                            padding: 15px;
                            border-radius: 5px;
                        }
                    
                        .approve-file-steps {
                            padding: 15px;
                            padding-top: 0;
                            width: 100%;
                            display: flex;
                            flex-direction: column;
                            align-items: flex-start;
                            justify-content: flex-start;
                            gap: 10px;
                    
                            .approve-file-step {
                                width: 100%;
                                display: flex;
                                flex-direction: column;
                                align-items: flex-start;
                                justify-content: flex-start;
                                gap: 5px;
                                border-radius: 7px;
                                overflow: hidden;
                                border: 1px solid #ccc;
                                background: #fff;
                                border-radius: 7px;
                    
                                .approve-file-step-header {
                                    border-radius: 5px 5px 0 0; 
                                    background: #ccc;
                                    padding-left: 17px;
                                    color: #fff;
                                    width: 100%;
                                    display: flex;
                                    flex-direction: row;
                                    align-items: center;
                                    justify-content: space-between;
                                    overflow: hidden;
                    
                                    .approve-file-step-header-title {
                                        font-weight: bold;
                                        display: flex;
                                        align-items: center;
                                        justify-content: flex-start;
                                        gap:2px;
                                    }
                    
                                    .approve-file-step-header-action {
                                        display: flex;
                                        flex-direction: row;
                                        align-items: center;
                                        justify-content: flex-end;
                                        gap: 5px;
                                    }
                                }
                    
                                .approve-file-step-body {
                                    width: 100%;
                                    padding: 10px;
                    
                                    .approve-file-step-users {
                                        width: 100%;
                                        display: flex;
                                        flex-direction: column;
                                        align-items: flex-start;
                                        justify-content: flex-start;
                                        gap: 15px;
                    
                                        .approve-file-step-user {
                    
                                            width: 100%;
                                            display: flex;
                                            flex-direction: row;
                                            align-items: center;
                                            justify-content: space-between;
                                            gap: 5px;
                    
                                            .approve-file-step-user-detail {
                                                display: flex;
                                                flex-direction: row;
                                                align-items: center;
                                                justify-content: flex-start;
                                                flex-wrap: nowrap;
                                                gap: 5px;
                    
                                                .approve-file-step-user-avatar {
                                                    width: 30px;
                                                    height: 30px;
                                                    border-radius: 50%;
                                                    position: relative;
                                                    z-index: 9;
                    
                                                    .top-connector {
                                                        width: 2px;
                                                            height: 65%;
                                                            background: #c1c1c1;
                                                            position: absolute;
                                                            margin-left: calc(-50% - 1px);
                                                            top: -50%;
                                                            z-index: -1;
                                                    }
                                                }
                                            }
                    
                                            .approve-file-step-user-status {
                                                font-weight: bold;
                                            }
                                        }
                                    }
                                }
                            }
                            .approve-file-step-active {
                                border: 2px solid #0144E4;
                                border-radius: 8px !important;
                                overflow: hidden;
                            }
                        }
                }
                
            }
        }
        .approve-file-active{
            border: 2px solid #0144E4!important;
            border-radius: 8px !important;
            overflow: hidden;
        }
        
    }
}

.approve-file-step-user-change-requested{
    position: relative;

        display: flex;
        align-items: center;
        justify-content: flex-start;
        margin-left: 5px;
    .added-file{
        display: block;
        width: 30px;
        height: 30px;
        position: relative;
        z-index: 9;
        display: flex;
            align-items: center;
            justify-content: flex-start;
        .top-connector {
            width: 2px;
                height: 70%;
                background: #c2c2c2;
                position: absolute;
                left: calc(50% - 6px);
                top: -50%;
                z-index: -1;
            }
    }
}

.bg-file-approved{
    background: $approve-green;
    color: #fff;
}

.bg-file-rejected{
    background: $approve-red;
    color: #fff;
}

.bg-file-pending{
    background: #040025;
    color: #fff;
}
.bg-file-created {
    background: #040025;
    color: #fff;
}

// data-tooltip-unhoverable="true"

[data-tooltip-unhoverable="true"] {
    display: none!important;
}