// Styles

// Main styles

@import './bootstrap.scss';
@import './app.scss';

// Shreyu Icons
@import './icons.scss';

//Disable Chrome two fingers back/forward swipe
html,
body {
  overscroll-behavior-x: none;
}

.table-responsive-md, .table-responsive-lg, .table-responsive-sm, .table-responsive-xs {
  overflow-x: auto;
  max-width: 100%;
}

.bg-logo-color{
  background-color: #0144E4!important;
  outline: #0144E4!important;
  border: #0144E4!important;
}

.text-logo-color{
  color: #0144E4 !important;
}

.btn-logo-color {
  background-color: #0144E4 !important;
  outline: #0144E4 !important;
  border: #0144E4 !important;
  transition: all 300ms linear;
  font-weight: 600;
  &:hover {
    background-color: #002c93 !important;
  }
}

.modal-shadow-border {
  box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
}

.modal-header{
  border-bottom: 1px solid #e9ecef8e;
  /* Bottom shadow */
}

// role="dialog" selector

div[role=dialog]:not(.offcanvas) {
      display: block!important;
        backdrop-filter: blur(1px)!important;
        background: rgba(0, 0, 0, 0.6)!important;
}

.dots-loading {
  &::after {
    display: inline-block;
    animation: ellipsis 1.25s infinite;
    content: ".";
    width: 1em;
    text-align: left;
  }

  @keyframes ellipsis {
    0% {
      content: ".";
    }

    33% {
      content: "..";
    }

    66% {
      content: "...";
    }
  }
}

.nav-pills{
  padding: 8px;
}
.nav-pills .nav-link.active {
  color: #ffffff;
  background-color: #050027;
  box-shadow: 0 0.05rem 0.01rem rgb(75 75 90 / 8%);
}


body[data-sidebar-size=condensed] .left-side-menu #sidebar-menu .menu-title {
  display: block !important;
}


body[data-sidebar-size=condensed] .left-side-menu #sidebar-menu>ul>li:hover>.collapse>.nav-second-level-position-top,
body[data-sidebar-size=condensed] .left-side-menu #sidebar-menu>ul>li:hover>.collapsing>ul.nav-second-level-position-top {
  bottom: calc(-100% + 109px);
}