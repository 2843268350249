.company-onboarding {
    width: 100%;
    flex: 1;
    position: relative;
    justify-content: center;
    align-items: center;
    height: 100% !important;

    .create-or-join {
        align-items: center;
        justify-content: center;
        min-width: 100% !important;
        height: fit-content;
        display: flex;

        @media screen and (max-width: 991px) {
            padding: 0px 0px !important;
        }

        div.seperator {
            @media screen and (max-width: 991px) {
                position: absolute;
                width: 100%;
                height: 1px;
                top: 50%;
                transform: translateY(-50%);
                background-color: #e6e6e6;
                padding: 0px !important;
            }

            @media screen and (min-width: 992px) {
                display: block;
                position: absolute;
                left: 50%;
                transform: translateX(-50%);
                width: 1px !important;
                opacity: 0.5px !important;
                min-height: inherit;
                background-color: #e6e6e6;
                padding: 0px !important;
            }
        }

        .create-or-joing-row {
            min-height: 50%;
            width: 100% !important;
            margin: 0px !important;
            padding: 0px !important;

            @media screen and (min-width: 992px) {
                display: flex;
            }

            .option {
                min-height: calc(50vh - 88.5px);
                height: 100%;
                display: flex;
                padding: 0px 80px !important;
                color: #050027;

                .selected {}

                &:hover {
                    background-color: #050027;
                    color: white;
                }

                @media screen and (max-width: 991px) {
                    padding: 0px 40px !important;
                    min-height: 40vh;
                }

                cursor: pointer;
                align-items: center;
                justify-content: center;
                flex-direction: column;

                div.innerContent {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;

                    h1 {
                        color: inherit;
                        text-align: center;
                        font-weight: normal;
                        line-height: 30px;
                        font-size: 23px;
                    }
                }
            }
        }
    }
}