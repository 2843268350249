.modal-footer {
    min-height: 60px;

    button.addUserToCompany-finish {
        position: absolute;
        right: 15px;

        &:disabled {
            background-color: gray;
            border-color: gray;
            outline: none;
            cursor: none;
        }
    }
}


.add-user-to-company {
    .add-user-to-company-row {
        div.innerContent {
            width: 100%;
            height: 100%;

            .departments-flex {
                display: flex;
                align-items: center;
                height: 55px !important;

                button {
                    margin-left: 10px !important;
                }
            }

            @media screen and (min-width:992px) {
                padding: 0px 0px;
            }

            @media screen and (max-width:992px) {
                min-height: 100% !important;
            }

            input {
                border-color: black;
            }

            .select-role-type {
                width: 100% !important;

                * {
                    border-color: black !important;
                }
            }

            form {
                position: relative;
                left: 50%;
                top: 50%;
                transform: translateX(-50%) translateY(-50%);
            }

            .add-new-email-layer {
                button {
                    margin-top: 20px;
                    display: flex;
                    //border: none;
                    align-items: center;
                    outline: none;


                    span {
                        height: 100%;
                        margin-left: 10px;
                    }
                }
            }

        }

        div.sideBarContent {

            background-image: url("../../assets/images/auth-bg.jpg");
            background-size: cover;

            @media screen and (max-width:992px) {
                display: none !important;
                //display: none !important;
            }

            @media screen and (min-width:992px) {
                min-height: calc(60vh - 88.5px);
                height: 100%;
                max-height: calc(70vh);
                //display: flex !important;
            }

        }
    }
}