$approve-red: #F0233B;
$approve-green: #2EB818;

.status-badge{
    background-color: $approve-green;
    border-radius: 4px;
    color: #ffffff;
    display: inline-block;
    text-align: center;
    white-space: nowrap;
    width: 135px;
    height: 29px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
    font-size: 14px;
    line-height: 14px;
    margin: 0;
}
.pending {
    background-color: #C4C4C4!important;
}
.approved {
    background-color: $approve-green!important;
}
.rejected{
    background-color: $approve-red!important;
}
.canceled{
    background-color: $approve-red!important;
}
.created{
    background-color: #040025!important;
}
.color-pending {
    background: none;
    color: #fff!important;
}
.color-approved {
    background: none;
    color: #fff!important;
}
.color-rejected{
    background: none;
    color: #fff!important;
}
.color-canceled{
    background: none;
    color: #fff!important;
}
.color-created{
    background: none;
    color: #fff!important;
}
.color-reassigned{
    background: none;
    color: #fff!important;
}
.color-change_requested{
    background: none;
    color: #fff!important;
}

.step-status{
    width: 25px;
    height: 25px;
    border-radius: 50%;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    
}
.step-status-rejected{
    background: #cccccc;
}
.step-status-approved{
    background: #cccccc;
}