.aprrove{
    max-height: 500px;
    overflow-y: auto;


.header-fixed{
    box-shadow: 0 2px 4px 0 rgba(0,0,0,0.7);
}

.approve__header{
    background: white;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px 20px;
    padding: 10px 20px;
    margin-bottom: 20px;
    position: -webkit-sticky;
        position: sticky;
        top: 0;




    .approve__header__title{
        font-size: 20px;
        font-weight: 600;
        color: #000;
        width: 40%;
        input{
            width: 100%;
            height: 40px;
            border: 1px solid #e5e5e5;
            border-radius: 4px;
            padding: 0 10px;
            font-size: 14px;
            color: #000;
            &:focus{
                outline: none;
            }
        }
    }
    .approve__header__actions{
        display: flex;
        align-items: center;
        .approve__header__actions__button{
            color: #2D42FF;
            font-size: 14px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            line-height: 14px;
            
        }
    }

    
}
.steps{
    padding: 20px;
    padding-top: 0;
    .step {
        padding: 20px;
            width: 100%;
            background: #e9e9e9;
            margin-bottom: 15px;
            border-radius: 5px;
            .step-number{
                display: flex;
                align-items: center;
                justify-content: space-between;
            }
            .recipients {
                background: #ffffff;
                min-height: 100px;
                padding: 10px;
                display: flex;
                align-items: flex-start;
                justify-content: flex-start;
                flex-wrap: wrap;
                gap: 5px;
                border-radius: 5px;
                margin-bottom: 10px;
                .recipients-new {
                    width: 100%;
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                
                        input {
                            text-align: center;
                            border-bottom-right-radius: 0;
                            border-top-right-radius: 0;
                        }
                
                        .add-recipient-button {
                            border-bottom-left-radius: 0;
                            border-top-left-radius: 0;
                            border: 1px solid #050027;
                            outline: 1px solid #050027;
                        }
                    }

                .recipient {
                    width: 100%;
                    overflow: hidden;
                    border-radius: 5px;
                    border: 1px solid #e9e9e9;
                    padding: 10px;

                    .recipient-group {
                        width: 100%;
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        .recipient-label{
                            background: #3C7AD8;
                            color: #ffffff;
                            padding: 5px 10px;
                            border-radius: 10px;
                        }
                    }
                }
            }
            
            .step-settings{
                padding: 10px;
                display: flex;
                align-items: center;
                justify-content: space-between;
            }
        }

        
}






.recipient-suggestions{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
    gap: 5px;
    color: blue;

 
}

/* ENDLESS ROTATE */
.rotate {
    animation: rotate 1.5s linear infinite;
}

@keyframes rotate {
    to {
        transform: rotate(360deg);
    }
}
}
