    .backdrop-opaque-1 {
        background-color: rgba(0, 0, 0, 1);
        opacity: 0.9 !important;
    }

    .redux-generic-error-modal {
        font-family: "IBM Plex Sans",
            sans-serif;

        .modal-header {
            border-bottom: 1px solid #ebeef5 !important;

            .modal-title {
                letter-spacing: -0.5px;
                font-family: "IBM Plex Sans", sans-serif;
            }
        }


        .modal-body {
            display: flex;
            flex-direction: column;
            width: 100%;
            align-items: center;
            justify-content: center;
            min-height: 80px;
            font-family: "IBM Plex Sans", sans-serif;
            font-size: 15px !important;
            text-align: center;

            .div {
                text-align: center;
                width: 100%;
            }
        }

        .modal-footer {
            border-top: 1px solid #ebeef5 !important;
        }
    }