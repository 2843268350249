@mixin dotdotdot($width, $display: block) {
    width: $width;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    display: $display;
}

.reminder {
background-color: #f3f4f7;
border-radius: 4px;
    .reminder-item {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 5px;
        padding: 5px 10px;
        border: 1px solid #f3f4f7;
        border-radius: 5px;

        &:hover {
            background-color: #eeeeee;
        }

        .reminder-detail {
            display: flex;
            flex-direction: column;

            .reminder-detail-meta {
                .title {
                    font-weight: 400;
                    @include dotdotdot(200px);
                }

                .description {
                    font-size: 12px;
                    @include dotdotdot(200px);
                }
            }

            .reminder-users {
                padding: 4px;
            }

        }

        .reminder-action {}

    }
}

.reminder-guests-list {
    min-height: 100px;
    border: 1px dashed #eeeeee;
    padding: 10px;
}