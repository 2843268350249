.modal-dialog.modal-google-iframe {
    
        padding: 0 !important;
            width: 100vw !important;
            height: 95vh;
            max-width: 95%!important;
    }

.google-docs-iframe{
    width: 100%;
    height: 100%;
    border: none;
}

.modal-shadow-border{
    box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
}