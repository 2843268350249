.modal-dialog.modal-google-iframe {
    
        padding: 0 !important;
            width: 100vw !important;
            height: 95vh;
            max-width: 95%!important;
    }

.google-docs-iframe{
    width: 100%;
    height: 100%;
    border: none;
}
.iframe-wrapper{
    padding: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .gdocs-iframe-left {
            width: 75%;
                height: 100%;
                padding: 10px;
        }
                .gdocs-iframe-right {
                    width: 25%;
                    height: 100%;
                    padding: 10px;
                    background: #eeeeee;
                }
}
