.cdn-upload-dropdown {
    position: relative;
    width: 250px;
    display: flex;
    align-items: center;
    justify-content: center;

    .cdn-upload-dropdown-menu {
        position: absolute;
        width: 250px;
        left: -50%;
        top: -50%;

        a {
            svg {
                margin-right: 10px;
            }

            height: 45px;
            display: flex;
            align-items: center;
        }
    }
}