.redux-generic-success-modal{
                    font-family: "IBM Plex Sans",
                    sans-serif;
    .modal-header{
        border-bottom: 1px solid #ebeef5 !important;
        .modal-title{
                font-family: "IBM Plex Sans",sans-serif;
        }
    }
    .modal-body{
        display: flex;
        flex-direction: column;
        width: 100%;
        align-items: center;
        justify-content: center;
        min-height: 80px;
        font-family: "IBM Plex Sans",
        sans-serif;
        font-size: 15px !important;
        
        .div {
            width: 100%;
        }
    }
    .modal-footer{
        border-top: 1px solid #ebeef5 !important;
    }
}