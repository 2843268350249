.create-company {
    .create-company-row {
        div.innerContent {
            width: 100%;
            height: 100%;

            @media screen and (min-width:992px) {
                padding: 0px 50px;
            }

            @media screen and (max-width:992px) {
                min-height: 100% !important;
            }

            form {
                position: relative;
                left: 50%;
                top: 50%;
                transform: translateX(-50%) translateY(-50%);
            }

        }

        div.sideBarContent {

            background-image: url("../../assets/images/auth-bg.jpg");
            background-size: cover;

            @media screen and (max-width:992px) {
                display: none !important;
                //display: none !important;
            }

            @media screen and (min-width:992px) {
                min-height: calc(70vh - 88.5px);
                //display: flex !important;
            }

        }
    }
}