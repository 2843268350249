.drop-list {
    height: 350px;
    overflow-y: scroll;
    overflow-x: hidden;
    padding: 10px;
    border: 1px dashed #ccc;

}

.field-list {
    height: 500px;
    max-height: 100%;
    overflow-y: scroll;
    overflow-x: hidden;
}


.field-disabled {
    opacity: .6;
}