.cdn-import-popup {
    display: flex;
    width: 100%;
    flex-direction: column;
    padding: 40x 40px !important;

    .preparing-files {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
        min-height: 30vh;

        .preparing-files-description {
            text-align: center;
            margin-top: 30px;
        }
    }

    .cdn-import-title {
        font-size: 15px;
        width: 100%;
        margin-bottom: 20px;
        font-weight: bold;
        margin-left: 20px;
    }

    .cdn-import-group {
        display: flex;
        align-items: flex-start;
        width: 100% !important;
        padding: 20px;

        .cdn-import-source-button-container {
            display: flex;
            width: calc(33.3333% - 22px) !important;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            height: 130px;
            margin: 0px 10px 0px 10px;

            &:first-child {
                margin-right: 10px;
                margin-left: 0px;
            }

            &:last-child {
                margin-left: 10px;
                margin-right: 0px;
            }

            button.cdn-import-source-button {
                display: flex;
                flex-direction: column !important;
                align-items: center;
                justify-content: center;
                height: 100%;
                border: 2px solid lightgray;
                border-radius: 5px;
                width: 100%;
                background-color: white;
                color: black;
                box-shadow: 1px 0px 112px -53px rgba(0, 0, 0, 0.75);
                -webkit-box-shadow: 1px 0px 112px -53px rgba(0, 0, 0, 0.75);
                -moz-box-shadow: 1px 0px 112px -53px rgba(0, 0, 0, 0.75);

                img {
                    width: 100%;
                    height: 50px;
                    object-fit: contain;
                    margin-bottom: 10px;
                }
            }
        }
    }
}